:root {
  --black: #263238;
  --grey: #a9a9a9;
  --lightgrey: #f5f5f5;
  --lightblue: #3E95BA;
  --pink: #ad1457;
  --font-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
  padding: 0;
}

*:focus {
  outline: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 5px;
  overflow: hidden;
}

main {
  text-align: center;
}

textarea {
  font-family: var(--font-mono);
  font-size: 14px;
  line-height: 1.4;
  border: 1px dashed var(--grey);
  border-radius: 8px;
  padding: 10px;
  margin: 70px 0 auto;
  width: 680px;
  max-width: 95dvw;
  height: calc(100dvh - 90px);
  color: var(--black);
  caret-color: var(--lightblue);
  background-color: white;
}

.viewarea {
  width: calc(100dvw - 10px);
  height: calc(100dvh - 10px);
  position: absolute;
  top: 5px;
}

button {
  margin: 0 15px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50px;
  color: var(--grey);
  background-color: var(--lightgrey);
  cursor: pointer;
}

@media (hover: hover) {
  button:hover {
    color: var(--black);
  }
}

.buttonContainer {
  z-index: 100;
  position: fixed;
  top: 10px;
  width: 100%;
  left: 0;
}

/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.modal-content {
  font-family: var(--font-sans);
  font-size: 0.95rem;
  line-height: 1.35;
  text-align: left;
  color: var(--black);
  background-color: white;
  padding: 0.4rem 0.95rem;
  border-radius: 8px;
  width: 500px;
  max-width: 95dvw;
  max-height: 90dvh;
  overflow-y: auto;
  position: relative;
}

.modal-content p {
  margin: 0.8rem 0;
}

.modal-content h2 {
  margin: 1rem 0;
}

.modal-content ul {
  padding-left: 20px;
}

.modal-content li {
  margin-bottom: 10px;
}

.modal-content a {
  color: var(--lightblue);
  text-decoration: none;
  font-weight: bold;
}

.modal-content i {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--lightgrey);
}
